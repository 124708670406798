import React from 'react'
import * as Titles from '@arch-log/webapp.shared/Title'

import {
  Container,
  Header,
  Footer,
  Title,
  Article,
  ArticleTitle,
  ArticleBody,
  ParagraphList,
  Paragraph,
  ItemList,
  Item,
} from './Layout'

// import * as styles from './styles.module.scss'

/**
 *
 */
export const Content = () => (
  <Container>
    <Title>
      <Titles.StaticPageTitle>プライバシーポリシー</Titles.StaticPageTitle>
    </Title>
    <Header>
      丸紅アークログ株式会社（以下「当社」といいます。）は、お客様の個人情報保護の重要性に鑑み、個人情報保護法その他関係法令等を遵守すると共に、以下のとおり、プライバシーポリシーを定め、当社において収集した個人情報及び履歴・属性情報について、適切に管理し、取扱うことを約束いたします。
    </Header>

    <Article>
      <ArticleTitle>定義</ArticleTitle>
      <ArticleBody>
        本プライバシーポリシーにおいて、個人情報とは、生存する個人の情報であって、当該情報に含まれる氏名、生年月日その他の記述等により特定の個人を識別することができるもの（他の情報と容易に照合することができ、それにより特定の個人を識別することができることとなるものを含みます。）をいいます。また、履歴・属性情報とは、お客様がご利用されたサービスやご覧になった商品や広告の履歴、サイト内での操作履歴、位置情報、並びにお客様の業種やプロジェクトに関する情報（建設される建物の用途および竣工予定時期）等、それを蓄積することによってお客様の興味・嗜好の分析に供することができる情報であって、特定の個人を識別することができないものをいいます。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>利用目的</ArticleTitle>
      <ArticleBody>
        <ParagraphList>
          <Paragraph>
            当社は、お客様の個人情報を、以下の利用目的に必要な範囲で収集、蓄積及び利用いたします。
            <ItemList>
              <Item>サービスのご提供およびご案内のため</Item>
              <Item>サービスのご利用料金を請求するため</Item>
              <Item>サービス及び広告配信をカスタマイズするため</Item>
              <Item>お客様ご本人へのご連絡のため</Item>
              <Item>サービスの改善や新サービスの開発等に役立てるため</Item>
              <Item>
                個人を識別できない形式に加工した統計データを作成するため
              </Item>
              <Item>その他上記①から⑥に付随する目的</Item>
            </ItemList>
          </Paragraph>
          <Paragraph>
            当社は、お客様の履歴・属性情報を、以下の利用目的に必要な範囲で収集、蓄積及び利用します。
            <ItemList>
              <Item>サービスのご提供およびご案内のため</Item>
              <Item>サービス及び広告配信をカスタマイズするため</Item>
              <Item>サービスの改善や新サービスの開発等に役立てるため</Item>
              <Item>
                当社と契約を締結するメーカーその他の第三者に対して、その生産活動又は販促活動の便宜を提供するため
              </Item>
            </ItemList>
          </Paragraph>
        </ParagraphList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>利用目的の変更</ArticleTitle>
      <ArticleBody>
        当社は、お客様の個人情報及び履歴・属性情報の利用目的を相当の関連性を有すると合理的に認められる範囲内において変更することがあり、変更した場合にはお客様に通知しまたは公表いたします。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>利用の制限</ArticleTitle>
      <ArticleBody>
        当社は、個人情報保護法その他の法令により許容される場合を除き、利用目的の達成に必要な範囲を超えて個人情報及び履歴・属性情報を取扱いません。但し、次の場合はこの限りではありません。
        <ItemList>
          <Item>お客様から事前の同意を得た場合</Item>
          <Item>法令に基づく場合</Item>
          <Item>
            人の生命、身体または財産の保護のために必要がある場合であって、お客様の同意を得ることが困難である場合
          </Item>
          <Item>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、お客様の同意を得ることが困難である場合
          </Item>
          <Item>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、お客様の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがある場合
          </Item>
        </ItemList>
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>適正な取得</ArticleTitle>
      <ArticleBody>
        当社は、お客様から個人情報及び履歴・属性情報を適切に取得し、偽りその他不正の手段により取得しません。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>安全管理</ArticleTitle>
      <ArticleBody>
        当社は、個人情報及び履歴・属性情報の安全な管理のため、適法かつ公正な管理を実施すると共に、当社の役員及び従業員に対し、日常業務における個人情報及び履歴・属性情報の適正な取扱いを徹底させます。また、個人情報及び履歴・属性情報の取扱いの全部又は一部を委託する場合は、委託先において個人情報及び履歴・属性情報の安全管理が図られるよう、必要かつ適切な措置を講じます。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>第三者への提供</ArticleTitle>
      <ArticleBody>
        当社は、お客様ご本人の事前の同意を得た場合又は法令に基づく場合を除き、個人情報を第三者に提供いたしません。なお、当社は、お客様の履歴・属性情報を第三者に提供することができるものとします。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>お問い合わせ窓口</ArticleTitle>
      <ArticleBody>
        当社が取扱うお客様の個人情報又は履歴・属性情報に関するお問い合わせは、以下のお問い合わせフォームから送信してください。
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>開示、訂正、利用停止等</ArticleTitle>
      <ArticleBody>
        お客様の個人情報及び履歴・属性情報の開示、訂正、利用停止等に関する請求に際しましては、請求の内容を記載した書面と共に、当社所定の書類を以下の宛先にご郵送ください。ただし、請求の際には、個人情報保護法その他の法令により当社が開示義務を負わない場合はお客様の上記請求に対応できない場合がありますことをご了承ください。
        <br />
        <br />
        宛先: 丸紅アークログ株式会社　カスタマーサポート係
        <br />
        住所: 〒105-0023 東京都港区芝浦1-3-3　浜松町ライズスクエア2階
      </ArticleBody>
    </Article>

    <Article>
      <ArticleTitle>プライバシーポリシーの改訂</ArticleTitle>
      <ArticleBody>
        取扱うお客様の個人情報及び履歴・属性情報の安全管理を適切に行うため、当社は、本プライバシーポリシーを随時見直し改訂します。本プライバシーポリシーの規定内容を改訂した場合は、当社の運営するウェブサイト上に公表して皆さまにお知らせいたします。
      </ArticleBody>
    </Article>
    <Footer>以上</Footer>
  </Container>
)
