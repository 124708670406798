import React from 'react'

// Module imports
import { withLanguage } from '@arch-log/webapp.modules/i18n/components/withLanguage'

// Local imports
import { Content as ContentJa } from './Content.ja.jsx'
import { Content as ContentEn } from './Content.en.jsx'

/**
 */
export const Content = withLanguage(({ language, ...props }) => {
  switch (language) {
    case 'ja':
      return <ContentJa {...props} />
    case 'en':
    default:
      return <ContentEn {...props} />
  }
})
