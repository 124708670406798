// extracted by mini-css-extract-plugin
export var Container = "styles-module--Container--uTWvj";
export var Documentation_Article = "styles-module--Documentation_Article--pS7JI";
export var Documentation_Article_Title = "styles-module--Documentation_Article_Title--BH1PY";
export var Documentation_ItemList = "styles-module--Documentation_ItemList--OGByr";
export var Documentation_ItemList_Item = "styles-module--Documentation_ItemList_Item--IKlyl";
export var Documentation_ParagraphList = "styles-module--Documentation_ParagraphList--64OVG";
export var Documentation_ParagraphList_Item = "styles-module--Documentation_ParagraphList_Item--3r-8T";
export var Footer = "styles-module--Footer--m0jrS";
export var Header = "styles-module--Header--BudOd";
export var Title = "styles-module--Title---9AWS";
export var Title_Text = "styles-module--Title_Text--ZwXIR";